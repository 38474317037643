import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames"
export const useStoreOrderType = create(persist((set, get) => ({
    order_type: {},
    kodeOutlet: '011',
    _hasHydratedOrderType: false,
    setHasHydratedOrderType: (state) => {
        set({  _hasHydratedOrderType: state  });
    },
    updateOrderType: (newOutlet) => {
        set({ order_type: newOutlet })
    }, 
}),
    {
        name: localStorageNames.ORDER_TYPE_CATEGORY,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedOrderType(true)
        }
    }
))