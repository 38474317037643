const localStorageNames = {
  SPLASH: 'splash',
  LOGO: 'logo',
  CREDENTIAL: 'creds',
  OUTLET: 'outlet_information',
  USER: 'user_information',
  ORDER_TYPE_CATEGORY: 'list_order_type_category',
  ORDERCATEGORY: 'order_category',
  CATEGORY: 'category',
  PRODUCTS: 'list_product',
  CART_ITEMS: 'cart_items',
  PAYMENT_ITEMS: 'payment_items',
  THEME_COLOR: 'theme_color',
  SETTING: 'setting',
  FEEDBACK: 'list_feedback',
  FILLED_FEEDBACK: 'filled_feedback',
  TRANSACTIONS_ID: 'transactions_id',
  PAYMENT_METHOD: 'payment_method',
  VIEW_BILL_ID: 'view_bill_id',
  VIEW_BILL: "view_bill",
  IS_VIEW_BILL: 'is_view_bill',
  CUSTOM_PARAMETER: 'custom_parameter',
  SPREAD_CATEGORY: 'spread_category',
  LONG_URL: 'long_url',
  MINIAPP_REQ_ID: 'miniapp_request_id',
  DOMAIN_SERVER: 'domain_server_rdo',
  NUTRI_GRADE: 'nutri_grade',
  SICC_MEMBER: 'sicc_member',
  ORDER_NUMBER: 'order_number'
};

export default localStorageNames;
