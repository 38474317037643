import URLS from '@/URLS';
import { useEffect, useState } from "react";
import { useStoreFirstCredential } from "@state/useStoreFirstCredential";
import {
    sendPostMessageToParent,
} from '@functions/trafficController';
import { useStoreNutriGrade } from "@state/useStoreNutriGrade";
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow'
import { useStoreOrderType } from '@state/useStoreOrderType';

const useRealmFetch = () => {
    const history = useHistory();
    const { credentials, getUserLogin } = useStoreFirstCredential(state => ({
        credentials: state.credentials,
        getUserLogin: state.getUserLogin
    }))
    const { updateNutriGrade } = useStoreNutriGrade(useShallow(state => ({ updateNutriGrade: state.updateNutriGrade })))
    const { updateOrderType } = useStoreOrderType(useShallow(state => ({ updateOrderType: state.updateOrderType })))

    const [realmConfig, setRealmConfig] = useState()

    async function fetchNutriGrade() {
        try {
            const userLogin = await getUserLogin()
            const response = await userLogin.callFunction(
                'getProductNutriGrade'
            );
            updateNutriGrade(response)
        } catch (error) { 
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchRealmConfig() {
        try {
            const userLogin = await getUserLogin()
            const response = await userLogin.callFunction(
                'getDataAuthStore',
                credentials);
            setRealmConfig(response)
        } catch (error) { 
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchOrderTypeData() {
        try {
            const userLogin = await getUserLogin()
            const response = await userLogin.callFunction(
                'getOrderTypeData',
                credentials);
            updateOrderType(response);
        } catch (error) { 
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    useEffect(() => {
        fetchRealmConfig()
        fetchNutriGrade()
        fetchOrderTypeData()
    }, [credentials])

    return realmConfig
}

export default useRealmFetch