import React, { useState, useContext, useRef } from 'react';
import { useGeneralContext } from '@context/GeneralContext';
import { useTranslation } from 'react-i18next';
import localStorageNames from '@data/localStorageNames';

// realm
import * as Realm from 'realm-web';
import { generalErrorHandler } from '@functions/errorHandler';
import { sendErrorData } from '@functions/ErrorMessagesSender';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';

const REALM_APP_ID =
  import.meta.env[`VITE_REACT_APP_REALM_CONFIGURATION_${import.meta.env.MODE}`];
const realmApp = new Realm.App({ id: REALM_APP_ID });

export const RealmContext = React.createContext([{}, () => { }]);

const RealmProvider = ({ children }) => {
  const { openGeneralModal } = useGeneralContext();
  const { t } = useTranslation();
  const [state, setState] = useState({
    transaction: {},
    realmCredential: {},
    firstOpenApp: true,
    messageRetry: '',
  });

  let userLogin = null;
  let intervalCount = 0;

  const launch = useRef();

  const setLocalState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getLocalState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const {
    credentials,
    checkValidCredentials,
    getUserLogin,
    getCredentials
  } = useStoreFirstCredential(state => ({
    credentials: state.credentials,
    checkValidCredentials: state.checkValidCredentials,
    getUserLogin: state.getUserLogin,
    getCredentials: state.getCredentials
  }))
  const { getOrderMethod, getSetting } = useStoreSettings(state => ({
    getOrderMethod: state.getOrderMethod,
    getSetting: state.getSetting
  }))

  const {
    getOutletInfo: getOutletData,
    getOutletId,
    getTableName,
  } = useStoreOutlet(state => ({
    getOutletInfo: state.getOutletInfo,
    getOutletId: state.getOutletId,
    getTableName: state.getTableName
  }))

  const wait = () => {
    return new Promise((res, _) => setTimeout(() => res(), 500));
  };

  const checkIsLogin = () => {
    return realmApp?.currentUser;
  };

  const getTransactionsId = () => {
    return JSON.parse(localStorage.getItem(localStorageNames.TRANSACTIONS_ID));
  };

  const saveTransactionsId = async (transactionId) => {
    let tempTransactionsId = [];
    if (getTransactionsId()) {
      tempTransactionsId = getTransactionsId();
    }
    tempTransactionsId.push(transactionId);

    localStorage.setItem(
      localStorageNames.TRANSACTIONS_ID,
      JSON.stringify(tempTransactionsId),
    );
  };

  const callClientTransaction = async (payloadData) => {
    console.log('RF client trans---------');
    const userLogin = await getUserLogin();
    const result = await userLogin.callFunction('clientTransaction', {
      method: 'POST',
      data: {
        ...payloadData,
      },
    });
    return result;
  };

  const promiseHitRealmTransaction = (payloadData, counter) => {
    console.log('promise realm trans----------')
    return new Promise(async (resolve, reject) => {
      if (counter === 3) {
        reject({ message: 'failed to fetch' });
        return;
      }
      const result = await callClientTransaction(payloadData);
      if (result) {
        resolve(result);
      } else {
        setTimeout(async () => {
          try {
            counter++;
            const result = await promiseHitRealmTransaction(
              payloadData,
              counter,
            );
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, 1000);
      }
    });
  };

  const hitRealmTransaction = async (payloadData) => {
    if (credentials) {
      console.log('hit realm transaction--------');
      let result;
      try {
        result = await promiseHitRealmTransaction(payloadData, 0);
        await saveTransactionsId(result);
        return result;
      } catch (error) {
        // generalErrorHandler(error, url, callback);
        let unexpectedError = generalErrorHandler(error, null, () =>
          openGeneralModal({
            title: t('networkError'),
            content: t('errorParameter'),
          }),
        );

        if (unexpectedError) {
          sendErrorData({
            message: `${error.message} | ${result ? JSON.stringify(result.json()) : 'null result from server'
              }`,
            table: getTableName(),
            file: 'RealmContext.js',
            func: 'hitRealmTransaction()',
          });
        }

        return { errorMessage: error.message, errorStatus: 'parameter' };
      }
    }
  };


  const hitRealmTransactionStripe = async (data) => {
    const credentials = Realm.Credentials.function(checkValidCredentials());
    const user = await realmApp.logIn(credentials);
    const result = await user.callFunction('stripePaymentSucces', {
      method: 'POST',
      _id: data.id,
      SubFunctionID: parseInt(data.subFunctionID),
    });
    // result true || false
    return result;
  };

  const isLastTransactionExist = async (filter) => {
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'IS_LAST_TRANSACTION_EXISTS',
      filter: { ...filter },
    });
    return response;
  };

  const getLastTransaction = async (filter) => {
    console.log('RF get last trans-----');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'GET_LAST_TRANSACTION',
      filter: { ...filter },
    });
    return response;
  };

  const getDetailTransaction = async (filter) => {
    console.log('RF get detail-----');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'GET_DETAIL',
      filter: { ...filter },
    });
    return response;
  };

  const getSessionAdyen = async (filter) => {
    console.log('RF get session adyen-----');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('createAdyenSession',
      filter,
    );
    // const response = undefined
    return response;
  };

  const getHistoryTransaction = async (filter) => {
    console.log('RF history trans-----');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'HISTORY',
      filter: { ...filter },
    });
    return response;
  };

  const getHistoryPaymentOrder = async () => {
    let outletInfo = getOutletData();
    let transactionsId = getTransactionsId();
    const mapTransId = transactionsId.map((data) => {
      return new Realm.BSON.ObjectId(data);
    });
    const filterById = {
      $in: mapTransId,
    };
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'HISTORY_VIEW',
      filter: { _id: filterById, table_id: outletInfo.tableId },
    });
    return response;
  };

  const insertLogStore = async (prm = '', getCollection) => {
    const dataLog = {
      _id: new Realm.BSON.ObjectID(),
      _partition: getOutletId(),
      createdAt: new Date(),
      status: 'miniapp_check',
    };

    if (prm !== '') {
      dataLog['parameter'] = prm;
    }

    await getCollection.insertOne(dataLog);

    const queryFilter = {
      filter: {
        'fullDocument._partition': getOutletId(),
        'fullDocument._id': dataLog._id,
      },
    };

    return queryFilter;
  };

  const updateTable = async (getCollection) => {
    const outletInfo = getOutletData()

    await getCollection.updateOne(
      {
        _partition: getOutletId(),
        table_id: outletInfo.tableId,
      },
      {
        $set: {
          updatedAt: new Date(),
          status: 'miniapp_check',
        },
      },
    );

    const queryFilter = {
      filter: {
        'fullDocument._partition': getOutletId(),
        'fullDocument.table_id': outletInfo.tableId,
      },
    };

    return queryFilter;
  };

  let miniAppCounter = 1;
  const watchListener = async (prm, from, getCollection, queryFilter) => {
    console.log('watch listener miniapp check ---------');
    // console.log(queryFilter)
    // queryFilter.then(item => console.log(item))

    const watchCollection = getCollection.watch(queryFilter);

    let timeout = setTimeout(() => {
      if (from === 'confirm') {
        let retry = miniAppCounter + 1;
        setLocalState({ messageRetry: retry === 2 ? '.' : '..' });
        if (miniAppCounter < 3) {
          const miniappAvailable = checkMiniAppConnection(prm, 'confirm');
          if (!miniappAvailable) {
            miniAppCounter = 1;
            return;
          }
        } else if (miniAppCounter === 3) {
          miniAppCounter = 1;
          watchCollection.return();
          sendPostMessageToParent('path', URLS.MINIAPP_FAILURE);
          window.location.replace(URLS.MINIAPP_FAILURE);
        }
        miniAppCounter++;
      } else {
        watchCollection.return();
        sendPostMessageToParent('path', URLS.MINIAPP_FAILURE);
        window.location.replace(URLS.MINIAPP_FAILURE);
      }
    }, 20000);

    let result = null;
    for await (const change of watchCollection) {
      if (change.operationType === "update" && change.fullDocument.status === 'miniapp_ok') {
        clearTimeout(timeout);
        result = true;
        break;
      }
    }
    return result;
  };

  const checkMiniAppNormalOrder = async (
    prm,
    from,
    collection,
    getCollection,
  ) => {
    let queryFilter =
      collection === 'tables'
        ? await updateTable(getCollection)
        : await insertLogStore('', getCollection);
    let resultWatch = watchListener(prm, from, getCollection, queryFilter);
    return resultWatch;
  };

  const checkMiniAppQuickOrder = async (prm, from, getCollection) => {
    let queryFilter = await insertLogStore(prm, getCollection);
    let resultWatch = watchListener(prm, from, getCollection, queryFilter);
    return resultWatch;
  };

  const checkMiniAppConnection = async (prm = '', from = '') => {
    console.log('hit miniapp check');
    let outletInfo = getOutletData()
    try {
      const collection = getSetting().miniAppCheck === 'table' ? 'tables' : 'store_log';
      const credentials = await getUserLogin();
      const getCollection = credentials
        .mongoClient('mongodb-atlas')
        .db('RDO')
        .collection(collection);

      if ((outletInfo && getOrderMethod() === 'normal_order') ||
        (outletInfo &&
          getOrderMethod() === 'quick_order')
      ) {
        let result = checkMiniAppNormalOrder(prm, from, collection, getCollection);
        return result;
      } else if (getOrderMethod() === 'quick_order') {
        let result = checkMiniAppQuickOrder(prm, from, getCollection);
        return result;
      } else {
        return true;
      }
    } catch (error) {
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} `,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'checkMiniAppConnection()',
        });
      }
    }
  };


  const sendClientPayment = async (
    payloadData,
    transactionId,
    subFunctionId,
  ) => {
    if (credentials) {
      const credentials = await getUserLogin();
      const result = await credentials.callFunction('clientPayment', {
        method: 'POST',
        data: {
          ...payloadData,
        },
        id_transaction: transactionId,
        SubFunctionID: parseInt(subFunctionId),
      });
      if (result) {
        saveTransactionsId(transactionId);
      }
      return result;
    }
  };


  let retryMiniappReq = 0;
  const retryMiniappReqWatch = async (id) => {
    console.log("retryMiniappReqWatch")
    if (retryMiniappReq < 1) {
      retryMiniappReq += 1;
      if (await miniappReqIsUpdated(id)) return true;
      return await watchMiniAppReq(id);
    } else {
      return false;
    }
  };

  const miniappReqIsUpdated = async (id) => {
    console.log("miniappReqIsUpdated")
    try {
      const credentials = await getUserLogin();
      const getMiniappReq = credentials
        .mongoClient('mongodb-atlas')
        .db('RDO')
        .collection('miniapp_request');

      const maReq = await getMiniappReq.findOne(
        { _id: Realm.BSON.ObjectId(id) },
        { status: 1, message: 1 }
      );

      if (maReq && maReq.status === 'updated') return true;
      // Handle case where status === 'not_updated', send error message to FE
      return false;
    } catch (error) {
      console.error("Error in miniappReqIsUpdated:", error);
      return false;
    }
  };

  const watchMiniAppReq = async (id) => {

    try {
      return await new Promise(async (resolve) => {
        const credentials = await getUserLogin();
        const getMiniappReq = credentials
          .mongoClient('mongodb-atlas')
          .db('RDO')
          .collection('miniapp_request');

        const watchMiniap = getMiniappReq.watch({ _id: [Realm.BSON.ObjectId(id)] });

        let timeout = setTimeout( async () => {
          watchMiniap.return();
          const retryMaReq = await retryMiniappReqWatch(id);
          resolve(retryMaReq);
        }, 10000);

        for await (const change of watchMiniap) { 
          if (change.fullDocument.status === 'updated') {
            clearTimeout(timeout);
            watchMiniap.return();
            resolve(true);
            break;
          }
        }
        resolve(false);
      });
    } catch (error) {
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} `,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'watchMiniAppReq()',
        });
      }
    }
  };

  const getProductGroup = async (filter) => {
    console.log('RF product group --------');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('getProductGroup',
      filter,
    );
    return response;
  };

  const getServiceCharge = async (filter) => {
    console.log('RF service charge --------');
    const userLogin = await getUserLogin();
    const response = await userLogin.callFunction('clientServiceCharge',
      filter,
    );
    return response;
  };
 
  return (
    <RealmContext.Provider
      value={{
        getLocalState,
        setLocalState,
        hitRealmTransaction,
        hitRealmTransactionStripe,
        sendClientPayment,
        getTransactionsId,
        saveTransactionsId,
        isLastTransactionExist,
        getLastTransaction,
        getDetailTransaction,
        getHistoryTransaction,
        getHistoryPaymentOrder,
        checkIsLogin,
        checkMiniAppConnection,
        getSessionAdyen,
        watchMiniAppReq,
        getProductGroup,
        getServiceCharge,
      }}>
      {children}
    </RealmContext.Provider>
  );
};

export const useRealmContext = () => {
  const value = useContext(RealmContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default RealmProvider;
