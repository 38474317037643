import { useEffect } from 'react'
import { setLocalStorage } from '@data/localStorageSetter';
import {
  useStoreAppData
} from '@state/useGlobalState';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStorePayment } from '@state/useStorePaymentMethod';
import { useStoreFeedback } from '@state/useStoreFeedback';
import { useStoreLogo } from '@state/useStoreLogo';
import { useStoreSpreadProduct } from '@state/useStoreSpreadProduct';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { saveProductData } from '@data/localStorageSetter';
import { useStoreOrderType } from '@state/useStoreOrderType';

export default function useSetupApp() {
  const { appData } = useStoreAppData(state => ({ appData: state.appData }))
  const data = appData
  const { updateSettings } = useStoreSettings(state => ({ updateSettings: state.updateSettings }))
  const { updateOutlet } = useStoreOutlet(state => ({ updateOutlet: state.updateOutlet }))
  const { updateAppColor } = useStoreAppColor(state => ({ updateAppColor: state.updateAppColor }))
  const { updateLogoUrl } = useStoreLogo(state => ({ updateLogoUrl: state.updateLogoUrl }))
  const { updateFeedback } = useStoreFeedback(state => ({ updateFeedback: state.updateFeedback }))
  const { updatePayment } = useStorePayment(state => ({ updatePayment: state.updatePayment }))
  const { updateProducts } = useStoreProduct(state => ({ updateProducts: state.updateProducts }))
  const { updateSpreadProduct } = useStoreSpreadProduct(state => ({ updateSpreadProduct: state.updateSpreadProduct }))
  const { order_type } = useStoreOrderType(state => ({
    order_type: state.order_type
  }))
  
  const insertProduct = (category) => {
    let products = [];
    category.products.forEach((product) => {
      let tempProduct = { ...product };
      delete tempProduct.prep_group;
      // delete tempProduct.variant_item;
      products.push(tempProduct);

      setLocalStorage(
        `product.${product.uuid_product}`,
        JSON.stringify({
          modifier_group: product.modifier_group,
          prep_group: product.prep_group,
          sub_product: product.sub_product,
          variant_item: product.variant_item,
        }),
      );
    });

    setLocalStorage(
      `product.${category.uuid_product_category}`,
      JSON.stringify(products),
    );
  };

  const spreadProductData = (data_product) => {
    if (Array.isArray(data_product) && data_product.length > 0) {
      let categories = [];
      data_product.forEach((category) => {
        if (
          Array.isArray(category.categories) &&
          category.categories.length > 0
        ) {
          category.categories.forEach((category2) => {
            categories.push({
              uuid_product_category: category2.uuid_product_category,
              url: category2.url,
              category_name: category2.category_name,
              menu_type: category2.menu_type,
            });
            if (
              Array.isArray(category2.products) &&
              category2.products.length > 0
            ) {
              insertProduct(category2);
            }
          });
        } else {
          categories.push({
            uuid_product_category: category.uuid_product_category,
            url: category.url,
            category_name: category.category_name,
            menu_type: category.menu_type,
          });
          if (Array.isArray(category.products) && category.products.length > 0) {
            insertProduct(category);
          }
        }
      });
      updateSpreadProduct(categories)
    } else {
      console.log("spread_categories false")
    }
  };

  useEffect(() => {
    const constructSetting = {
      isOrderable: data.config?.is_orderable,
      isDisplayCategory: data.config?.display_category,
      isDisplaySearch: data.config?.display_search,
      isDirectOrder: data.config?.direct_order,
      isImageDetail: data.config?.image_detail,
      isDisplayLogin: data.config?.display_login,
      orderMethod: data.config?.order_method || 'normal_order', // normal_order || payment_order || waiter_order || quick_order
      soldOutText: data.config?.sold_out_text,
      confirmOrderText: data.config?.confirm_order_text || '',
      placeholderParameter: data.config?.placeholder_parameter,
      isQRStoreParameter: data.config?.qrstore_parameter,
      tableName: data.config?.tableName,
      subFunctionID: data.config?.sub_function_id_stripe,
      qrcodeTitlePage: data.config?.qrcode_title_page,
      miniAppCheck: data.config?.miniapp_check,
      isShowViewBill: data.config?.is_view_bill_active,
      feedbackText: data.config?.feedback_text,
      isInfinityScroll: data.config?.view_type !== 'tab_menu',
      isShowImageType: data.config?.show_image_type,
      isProductOptionFold: data.config?.product_option_fold,
      isShowQrScanner: data.config?.show_qr_scanner,
      languages: data.config?.languages,
      maintenance: data.config?.maintenance_mode,
      customTable: data.config?.custom_table,
      useSiccMember: data.config?.use_sicc_member,
      weatherWarning: data.config?.use_weather_warning,
      qrStoreOrderType: data.config?.qr_store_order_type,
      qrTableOrderType: data.config?.qr_table_order_type,
      hasServiceCharge: data.config?.has_service_charge,
      timezone: data.config?.timezone,
    }
    
    const salestype =  {
      active: order_type?.result?.order_types.length,
      sales: order_type?.result?.order_types
    }

    const constructOutlet = {
      outletId: data.config?.storeUUID,
      openingHours: data.config?.openingHours,
      outletName: data.config?.restaurantName,
      tableId: data.config?.tableID,
      tableName: data.config?.tableName,
      salestype: salestype,
      currency: data.config?.currency,
      currencySymbol: data.config?.currency_symbol,
      localeCode: data.config?.locale_code,
      workHours: data.work_hours,
      out_operational_time_text: data.config?.custom_message
    };

    const constructColorApp = {
      primaryThemeColor: data.config?.primary_color || '#FF8A37',
      secondaryThemeColor: data.config?.secondary_color || '#FEEDE2',
      primaryFontColor: data.config?.primary_font_color || '#000000',
      secondaryFontColor: data.config?.secondary_font_color || '#ffffff',
      backgroundColor: data.config?.background_color || '#FFE7D7',
      backgroundProduct: data.config?.product_background_color || '#FFFFFF',
      headerAccordion: data.config?.header_color || '#FF911C',
      successAccordion: data.config?.success_color || '#00AA13',
      addButton: data.config?.add_to_cart_color || '#00AA13',
      backgroundAccordion: data.config?.background_accordion_color || '#FFFFFF',
    };
    if (data.config?.logoUrl) {
      updateLogoUrl(data.config?.logoUrl)
    }
    if (data.status && data.status === "closed") {
      const constructOutlet = {
        out_operational_time_text: data.out_operational_time_text,
        status: data.status
      };
      updateOutlet(constructOutlet)
    } else {
      updateOutlet(constructOutlet)
      updateSettings(constructSetting)
      updateAppColor(constructColorApp)
      if (data && data.config) {
        updateFeedback(data.feedback)
        updatePayment(data.config.payment_channels)
        saveProductData(data.product)
        updateProducts(data.product)
        spreadProductData(data.product)
      }
    }
    

  }, [data, order_type])


  return appData;
}
