import { saveTypeUser } from '@data/localStorageSetter';
import URLS from '@/URLS';
import { useEffect, useState } from 'react';
import useMiniAppWatchListener from './useMiniAppWatchListener';
import { useStoreAppData } from '@state/useGlobalState';
import useCheckSalesType from './useCheckSalesType';
import useCheckOpenCloseStore from '@hooks/useCheckOpenCloseStore';
import { useStoreOrderType } from '@state/useStoreOrderType';

export default function useConfigRedirect(valid, creds) {
  const { appData } = useStoreAppData(state => ({ appData: state.appData }))
  const { config } = appData
  const retryMiniappWatch = useMiniAppWatchListener()
  const [redirectUrl, setRedirectUrl] = useState("")
  const checkSalesType = useCheckSalesType();
  const { order_type } = useStoreOrderType(state => ({
    order_type: state.order_type
  }))

  const [ OrderTypeNewData ]= useCheckOpenCloseStore()
  const setupConfigRedirect = async () => {
    if (config) {
      const type = OrderTypeNewData().filter((e) => e.disabled);
      if (order_type.result.order_types.length === type.length) {
        setRedirectUrl(URLS.OUTLET_CLOSE)
      } else {
        const miniappReady = creds.miniapp ? true : await retryMiniappWatch(appData.miniapp_check_id);
        if (miniappReady) {
          if (config.display_login) {
            setRedirectUrl(URLS.LOGIN)
          } else {
            saveTypeUser('guest');
            ///dari qrstore config tableID null
            ///direct langsung ke takeaway
            ///jika null ke normal biasanya kalo ada isinya baru force
            setRedirectUrl(checkSalesType())
          }
        } else {
          setRedirectUrl(URLS.MINIAPP_FAILURE)
        }
      }
      return 1;
    }
    console.log('session exp-----');
    setRedirectUrl(URLS.SESSION_EXPIRED)
  };

  useEffect(() => {

    if (config && config !== undefined && config !== null && valid) {
      setupConfigRedirect()
    }
  }, [appData, valid])

  return redirectUrl
}
